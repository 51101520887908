import React from 'react';
import { AutoComplete, Popconfirm, Select, Message } from '../../components/base';
import FlagIcon from '../../components/FlagIcon';
import './common.scss';
import { CheckIsNullOrUndefined, showAjaxErrorMsg, CheckIsEmptyString } from '../../components/Common';
import Ajax from '../../components/Ajax';
import { getText } from '../../i18n/i18next';
import { CloseOutlined, AndroidOutlined } from '@ant-design/icons';
import AuthService from '../../components/AuthService';

const { isArray } = Array;
const AutoCompleteOption = AutoComplete.Option;
const Option = Select.Option;

const Auth = new AuthService();

export const ENSValidationStartDate = '2024-05-27';

export function GetPartyOption(parties, noRecordText = 'No record found', isLoading = false, isPredict = false, party_type = '') {
    return isLoading ?
        [<Option disabled key='loading' text=''>
            Loading...
        </Option>]
        :
        (isArray(parties) && parties.length > 0 ?
            parties.map(p => {
                let similarity = '';

                if (isPredict) {
                    if (['CNS', 'NOTIFY'].includes(party_type) && !CheckIsNullOrUndefined(p.Similarity) && !isNaN(p.Similarity)) {
                        similarity = (p.Similarity * 100).toFixed(2).toString() + '%';
                    }
                    else if (party_type === 'RELEASE_AGENT' && !CheckIsNullOrUndefined(p.release_score) && !isNaN(p.release_score)) {
                        similarity = (p.release_score * 100).toFixed(2).toString() + '%';
                    }
                    else if (party_type === 'NOMINATION_AGENT' && !CheckIsNullOrUndefined(p.nominate_score) && !isNaN(p.nominate_score)) {
                        similarity = (p.nominate_score * 100).toFixed(2).toString() + '%';
                    }
                }
                
                return (
                    <Option
                        key={p.party_id}
                        text={p.party_code_display ? `${p.full_name} (${p.party_code_display})` : p.full_name}
                        party_code={p.party_code}
                        //party_code_display={p.party_code_display}
                        party_name={p.full_name}
                        office_code={p.office_code}
                        address1={p.address1}
                        address2={p.address2}
                        address3={p.address3}
                        address4={p.address4}
                        city={p.city}
                        country_code={p.country_code}
                        is_auto_fill_display={p.is_auto_fill ? "Y" : "N"}
                        description={p.description}
                        party_group_type_id={p.party_group_type_id}
                        tel={p.tel}
                        fax={p.fax}
                        office_hour_from={p.office_hour_from}
                        office_hour_to={p.office_hour_to}
                        status={p.status}
                        customer_id={p.customer_id}
                        customer_full_name={p.customer_full_name}
                        customer_code={p.customer_code}
                    >
                        <span>
                            <span style={{ fontWeight: 'bold' }}>{p.full_name}</span>
                            <span>{p.party_code_display ? `(${p.party_code_display})` : ''}</span>
                            {
                                isPredict &&
                                <>
                                    <span style={{ float: 'right' }}>
                                        {isPredict ? <AndroidOutlined /> : ''}
                                    </span>
                                    <span style={{ float: 'right' }}>
                                        {isPredict ? similarity : ''}
                                    </span>
                                </>
                            }
                        </span>
                        <div><small style={{ whiteSpace: 'pre-wrap' }}>{p.full_addr}</small></div>
                    </Option>
                )
            })
            :
            [<Option disabled key={isPredict ? 'predict_all' : 'all' } text=''>
                <span>{noRecordText}</span>
                <span style={{ float: 'right' }}>
                    {isPredict ? <AndroidOutlined /> : ''}
                </span>
            </Option>]);
}

export function GetCustomerOption(customers, noRecordText = 'No record found', isLoading = false) {
    return isLoading ?
        [<Option disabled key='loading' text=''>
            Loading...
        </Option>]
        :
        (isArray(customers) && customers.length > 0 ?
        customers.map(c => (
            <Option key={c.customer_id} text={`${c.full_name} (${c.code})`} full_name={c.full_name} code={c.code}>
                <span style={{ fontWeight: 'bold' }}>{c.full_name}</span> <span>({c.code})</span>
            </Option>
        )) :
            [<Option disabled key='all' text=''>
            {noRecordText}
            </Option>]);
}

export function GetPortOption(ports, noRecordText = 'No record found') {
    return isArray(ports) && ports.length > 0 ?
        ports.filter(p => p.unCode !== '').map(p => (
            <Option key={p.unCode} text={`${p.name} (${p.unCode})`} handling_office_party_id={p.notify_office_party_id} port_name={p.name} countrycode={p.countryCode.toLowerCase()} countryname={p.countryName}>
                {p.name}
                <div>{p.unCode}</div>
            </Option>
        )) :
        [<Option disabled key='all' text=''>
            {noRecordText}
        </Option>];
}

export function GetCountryOption(countries, noRecordText = 'No record found') {
    return isArray(countries) && countries.length > 0 ?
        countries.map(c => (
            <Option key={c.country_code} text={`${c.country_name} (${c.country_code})`} country_name={c.country_name}>
                <FlagIcon code={c.country_code.toLowerCase()} size="lg" /> {c.country_name} ({c.country_code})
            </Option>
        )) :
        [<Option disabled key='all' text=''>
            {noRecordText}
        </Option>];
}

export function GetCityOption(cities, noRecordText = 'No record found') {
    return isArray(cities) && cities.length > 0 ?
        cities.map(c => (
            <Option key={c.city_name} text={`${c.city_name}`}>
                {c.city_name} ({c.city_code})
            </Option>
        )) :
        [<Option disabled key='all' text=''>
            {noRecordText}
        </Option>];
}

export function GetISFInfoOption(info, noRecordText = 'No record found', deleteRecord) {
    return isArray(info) && info.length > 0 ?
        info.map((i, idx) => {
            let addr = `${i.address1 || ''},${i.address2 || ''},${i.city || ''},${i.state || ''},${i.zip || ''},${i.country || ''}`.replace(/,+/g, ',');
            if (addr === ',') addr = '';
            let confirmMsg = "Are you sure to delete [" + i.name + "]?";
            return (
                <AutoCompleteOption
                    key={idx}
                    value={i.name}
                    text={i.name}
                    address1={i.address1}
                    address2={i.address2}
                    city={i.city}
                    state={i.state}
                    zip={i.zip}
                    country={i.country}
                    countryoforigin={i.countryOfOrigin}
                    isfprofileid={i.isf_profile_id}
                    //htscode={i.htsCode && i.htsCode.join(',')}
                >
                    <div style={{ display: "block" }}>
                        <div style={{ width: "90%", whiteSpace: "break-spaces", display: "inline-block" }}>{i.name}</div>
                        {
                            i.isf_profile_id > 0 &&
                            <div
                                style={{ zIndex: 99, verticalAlign: "top", width: "10%", display: "inline-block" }}
                                onClick={(e) => {
                                    e.preventDefault(); e.stopPropagation();
                                }}
                            >
                                <Popconfirm
                                    title={confirmMsg}
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={() => { deleteRecord && deleteRecord(i.isf_profile_id); }}
                                >
                                    <CloseOutlined style={{float: 'right', paddingTop: '5px'}} />
                                </Popconfirm>
                            </div>
                        }
                        <div style={{ width: "90%", whiteSpace: "break-spaces", display: "block" }}>{addr}</div>
                    </div>
                </AutoCompleteOption>
            );
        }) :
        [<AutoCompleteOption key='all' text=''>
            {noRecordText}
        </AutoCompleteOption>];
}

export function GetENSInfoOption(info, noRecordText = 'No record found', deleteRecord) {
    return isArray(info) && info.length > 0 ?
        info.map((i, idx) => {
            let addr = `${i.address1 || ''},${i.address2 || ''},${i.city || ''},${i.state || ''},${i.zip || ''},${i.country_code || ''}`.replace(/,+/g, ',');
            if (addr === ',') addr = '';
            let confirmMsg = "Are you sure to delete [" + i.name + "]?";
            return (
                <AutoCompleteOption
                    id={i.id}
                    key={idx}
                    value={i.name}
                    text={i.name}
                    address1={i.address1}
                    address2={i.address2}
                    city={i.city}
                    state={i.state}
                    zip={i.zip}
                    country_code={i.country_code}
                    phone={i.phone}
                    email={i.email}
                    eori_no={i.eori_no}
                >
                    <div style={{ display: "block" }}>
                        <div style={{ width: "90%", whiteSpace: "break-spaces", display: "inline-block" }}>{i.name}</div>
                        {
                            i.id > 0 &&
                            <div
                                style={{ zIndex: 99, verticalAlign: "top", width: "10%", display: "inline-block" }}
                                onClick={(e) => {
                                    e.preventDefault(); e.stopPropagation();
                                }}
                            >
                                <Popconfirm
                                    title={confirmMsg}
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={() => { deleteRecord && deleteRecord(i.id); }}
                                >
                                    <CloseOutlined style={{ float: 'right', paddingTop: '5px' }} />
                                </Popconfirm>
                            </div>
                        }
                        <div style={{ width: "90%", whiteSpace: "break-spaces", display: "block" }}>{addr}</div>
                    </div>
                </AutoCompleteOption>
            );
        }) :
        [<AutoCompleteOption key='all' text=''>
            {noRecordText}
        </AutoCompleteOption>];
}

export function GetCarrierOption(carrierList, noRecordText = 'No record found') {
    return isArray(carrierList) && carrierList.length > 0 ?
        carrierList.map(c => (
            <Option key={c.scac} text={`${c.display_name} (${c.scac})`} display_name={c.display_name} carrier_name={c.name}>
                {c.display_name} ({c.scac})
            </Option>
        )) :
        [<Option disabled key='all' text=''>
            {noRecordText}
        </Option>];
}

export function GetUnLocodeOption(unLocodes, noRecordText = 'No record found') {
    return isArray(unLocodes) && unLocodes.length > 0 ?
        unLocodes.filter(p => p.locode !== '').map(p => (
            <Option key={p.locode} text={p.name !== p.locode ? `${p.name} (${p.locode})` : p.name} un_locode_name={p.name} countrycode={p.country_code.toLowerCase()} countryname={p.country_name}>
                {p.name}
                <div>{p.locode}</div>
            </Option>
        )) :
        [<Option disabled key='all' text=''>
            {noRecordText}
        </Option>];
}

export function GetKeyValuePairOption(list, noRecordText = 'No record found') {
    return isArray(list) && list.length > 0 ?
        list.filter(p => p.locode !== '').map(p => (
            <Option key={p.pkey} value={p.pkey} style={{ width: '550px' }} text={p.pvalue}>
                <strong>{p.pkey}</strong>
                <div style={{ width: '500px', wordWrap: 'break-word', whiteSpace: 'normal' }}>{p.pvalue}</div>
            </Option>
        )) :
        [<Option disabled key='all' value='' text=''>
            {noRecordText}
        </Option>];
}

export function GetCFSOption(cfsList, noRecordText = 'No record found') {
    if (isArray(cfsList) && cfsList.length > 0) {
        let option_list = [];
        let w_relationship_list = cfsList.filter(p => p.has_party_relationship);
        let wo_relationship_list = cfsList.filter(p => !p.has_party_relationship);

        if (w_relationship_list && w_relationship_list.length > 0) {
            w_relationship_list.forEach(p => {
                option_list.push(
                    <Option
                        key={p.party_id}
                        text={p.party_code ? `${p.full_name} (${p.party_code})` : p.full_name}
                        party_code={p.party_code}
                    >
                        <span style={{ fontWeight: 'bold' }}>{p.full_name}</span> <span>{p.party_code ? `(${p.party_code})` : ''}</span>
                        <div><small>{p.full_addr}</small></div>
                    </Option>
                );
            });
        }

        if (option_list.length > 0 && wo_relationship_list && wo_relationship_list.length > 0) {
            option_list.push(
                <Option disabled key='separator' text='' className='separator'>
                    {}
                </Option>
            );
        }

        if (wo_relationship_list && wo_relationship_list.length > 0) {
            wo_relationship_list.forEach(p => {
                option_list.push(
                    <Option
                        key={p.party_id}
                        text={p.party_code ? `${p.full_name} (${p.party_code})` : p.full_name}
                        party_code={p.party_code}
                    >
                        <span style={{ fontWeight: 'bold' }}>{p.full_name}</span> <span>{p.party_code ? `(${p.party_code})` : ''}</span>
                        <div><small>{p.full_addr}</small></div>
                    </Option>
                );
            });
        }

        return option_list;
    }

    return (
        [<Option disabled key='all' text=''>
            {noRecordText}
        </Option>]
    );

    
}

//export function showAjaxErrorMsg(error){
//    if (error.response && error.response.status === 404) {
//        Message.warn(getText('msgNoRecordFound'), 10);
//    }
//    else if (error.response && error.response.data) {
//        Message.error(`${error.response.data.Message}`, 10);
//    }
//    else {
//        Message.error(`${error}`, 10);
//    }
//}

export async function openMobileDetailPage(record, uac) {
    const baseUrl = `${process.env.PUBLIC_URL}/api/v1`;
    let url = new URL(`${baseUrl}/Shipment/GetMobileSite`, window.location.href);

    if (!CheckIsNullOrUndefined(uac)) {
        let user_uid = uac.user_uid;

        if (CheckIsNullOrUndefined(user_uid)) {
            Message.error(getText('so.msgInvalidUser'), 10);
        }
        else {
            try {
                let res = await Ajax.get(url);
                if (res && isArray(res.data) && res.data.length > 0 && res.data[0] && res.data[0].pvalue) {
                    let mobileSite = res.data[0].pvalue;

                    //Check SO exists in mobile DB or not
                    let so_no = record.so_no;
                    let mobileURL = new URL(`${baseUrl}/Shipment/CheckSOExistsInMobile/${so_no}`, window.location.href);

                    res = await Ajax.get(mobileURL);
                    
                    if (res && res.data) {
                        if (!CheckIsNullOrUndefined(res.data.master_so_no)) so_no = res.data.master_so_no;

                        //add token
                        let tokenURL = new URL(`${baseUrl}/Shipment/AddTokenForRedirectMobileSite`, window.location.href);
                        tokenURL.searchParams.append('user_uid', uac.user_uid);
                        //tokenURL.searchParams.append('user_uid', '10000031');
                        res = await Ajax.post(tokenURL);

                        if (res && res.data && res.data.token) {
                            //window.open(mobileSite + `?soNo=SBO-ALPYV-8&token=${res.data.token}&row_no=0`, "MobileWindow");
                            window.open(mobileSite + `?soNo=${so_no}&token=${res.data.token}&row_no=0`);
                        }
                        else {
                            Message.error(getText('so.msgAddTokenFail'), 10);
                            return;
                        }
                    }
                    else {
                        Message.error(getText('so.msgSONotExistsInMobile'), 10);
                        return;
                    }
                }
                else {
                    Message.error(getText('so.msgMobileSiteNotFound'), 10);
                    return;
                }
            }
            catch (error) {
                showAjaxErrorMsg(error);
            }
        }
    }
    else {
        Message.error(getText('so.msgInvalidUser'), 10);
    }
}

//export function checkTBCContainerNoFormatValid(container_no) {
//    if (!CheckIsNullOrUndefined(container_no) && container_no.length >= 3 && container_no.length <= 5 && checkNormalCharactersOnly(container_no) && !checkWhiteSpace(container_no) && container_no.toLowerCase().includes('tbc')) {
//        return true;
//    }
    
//    return false;
//}

export async function getPOLHandlingOffice(pol_locode, showAllHDOffice, user_profile_id, handling_office_party_id) {
    const baseUrl = `${process.env.PUBLIC_URL}/api/v1`;
    let url = new URL(`${baseUrl}/party/getPOLHandlingOffice`, window.location.href);
    let uac = Auth.getUac();

    url.searchParams.append('pol_locode', pol_locode ? pol_locode : '');
    url.searchParams.append('include_party_id', handling_office_party_id ? handling_office_party_id : '');

    if (showAllHDOffice) {
        url.searchParams.append('show_all', true);
    }
    else if (uac && isArray(uac.menu_func_accesses))
    {
        if (uac.menu_func_accesses.findIndex(mfa => mfa.func_id === 'show_full_list_hd_office') === -1) {
            url.searchParams.append('user_profile_id', user_profile_id);
        }
        else if (CheckIsEmptyString(pol_locode) && CheckIsEmptyString(handling_office_party_id)){
            url.searchParams.append('show_all', true);
        }
    }

    try {
        return Ajax.get(url.toString())
            .then(res => {
                return res.data;
            });
    } catch (error) {
        if (error.response && error.response.status === 404) {
            return [];
        }
    }
}